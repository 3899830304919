import React, { useState } from 'react'
import classes from "./style/JobFinder.module.css";
import SuggestionKeywordList from "./AllSuggestedKeyword.json";

const JobSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isSuggestion, setIsSuggestion] = useState(true);

  const handleSearchInputClick = (word) => {
    setSearchTerm(word);
    setIsSuggestion(false);
  };

  const handleSearchInputChange = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
    setIsSuggestion(true);
  };

  const filterSuggestedKeyword = (searchTerm) => {
    return SuggestionKeywordList.filter(
      (word) =>
        word.Keyword.toLowerCase().startsWith(searchTerm.toLowerCase())
    );
  };

  const handleRedirect = () => {
    window.open( `https://www.wiraa.com/?type=global-jobs&title=${searchTerm}`, "_blank");
  };
  

  return (
    <div className={classes.innerCon}>
        <div className={classes.additionalInputCon}>
            <div className={classes.additionalInputDiv}>
                <p className={classes.inputplaceholder}>Explore More Jobs</p>
                <input value={searchTerm} onChange={(e) => handleSearchInputChange(e)} className={classes.addInputfield} placeholder='Enter job title...'/>
                <div className={classes.suggestCon}>
            {searchTerm && isSuggestion && filterSuggestedKeyword(searchTerm).map((item,i) => {
              return <div key={i} >
                <p className={classes.suggestedKeyword} onClick={() => {handleSearchInputClick(item.Keyword)}}>{item.Keyword}</p>
            </div>})}
            {searchTerm && isSuggestion && <div key={"keyword"} >
                <p className={classes.suggestedKeyword} onClick={() => {handleSearchInputClick(searchTerm)}}>{searchTerm}</p>
            </div>}
            </div>
            </div>
            
        </div>
        <div className={classes.btnCon}>
            <button className={classes.SearchBtn} onClick={handleRedirect}>Search Jobs</button>
        </div>
      </div>
  )
}

export default JobSearch
