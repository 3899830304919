import React from 'react';
import classes from "./styles/FullWidthWiraaAds.module.css";

export const FullWidthWiraaAds = () => {
  return (
    <a href="https://www.wiraa.com/" target="_blank" rel="noopener noreferrer" className={classes.link}>
      <div className={classes.container}>
        <div>
            <h6>Freelance or Full-Time? Find Both Here!</h6>
            <p>Access thousands of remote jobs and freelance projects globally.</p>
        </div>
           <button>Explore Now</button>
      </div>
      </a>
  )
}
