import React from 'react';
import SidebarLayout from '../layouts/SidebarLayout';
import ReactJs from '../tools/productivity/jobs/it jobs/ReactJsJob';
import { Route, Routes } from 'react-router-dom';
import DeveloperJob from '../tools/productivity/jobs/it jobs/DeveloperJob';
import WebDeveloperJob from '../tools/productivity/jobs/it jobs/WebDeveloperJob';
import AppDeveloperJob from '../tools/productivity/jobs/it jobs/AppDeveloperJob';
import ReactNativeJob from '../tools/productivity/jobs/it jobs/ReactNativeJob';
import JavaJob from '../tools/productivity/jobs/it jobs/JavaJob';
import AngularJob from '../tools/productivity/jobs/it jobs/AngularJob';
import CssJob from '../tools/productivity/jobs/it jobs/CssJob';
import HtmlJob from '../tools/productivity/jobs/it jobs/HtmlJob';
import NextJsJob from '../tools/productivity/jobs/it jobs/NextJsJob';
import WordpressJob from '../tools/productivity/jobs/it jobs/WordpressJob';
import NodeJsJob from '../tools/productivity/jobs/it jobs/NodeJsJob';
import PythonJob from '../tools/productivity/jobs/it jobs/PythonJob';
import DataScienceJob from '../tools/productivity/jobs/it jobs/DataScienceJob';
import ArtificialIntelligenceJob from '../tools/productivity/jobs/it jobs/ArtificialIntelligenceJob';
import DeepLearningJob from '../tools/productivity/jobs/it jobs/DeepLearningJob';
import MachineLearningJob from '../tools/productivity/jobs/it jobs/MachineLearningJob';
import DataAnalysisJob from '../tools/productivity/jobs/it jobs/DataAnalysisJob';
import IosJob from '../tools/productivity/jobs/it jobs/IosJob';
import FlutterJob from '../tools/productivity/jobs/it jobs/FlutterJob';
import AndroidJob from '../tools/productivity/jobs/it jobs/AndroidJob';
import KotlinJob from '../tools/productivity/jobs/it jobs/KotlinJob';
import SwiftJob from '../tools/productivity/jobs/it jobs/SwiftJob';
import UnrealEngineJob from '../tools/productivity/jobs/it jobs/UnrealEngineJob';
import UnityJob from '../tools/productivity/jobs/it jobs/UnityJob';
import GameDeveloperJob from '../tools/productivity/jobs/it jobs/GameDeveloper';
import DatabaseJob from '../tools/productivity/jobs/it jobs/DatabaseJob';
import SqlJob from '../tools/productivity/jobs/it jobs/SqlJob';
import MySqlJob from '../tools/productivity/jobs/it jobs/MySqlJob';
import MongoDBJob from '../tools/productivity/jobs/it jobs/MongoDbJob';
import QaEngineerJob from '../tools/productivity/jobs/it jobs/QaEngineer';
import BackendJob from '../tools/productivity/jobs/it jobs/BackendJob';
import TestingJob from '../tools/productivity/jobs/it jobs/TestingJob';
import FrontendJob from '../tools/productivity/jobs/it jobs/FrontendJob';
import FullStackJob from '../tools/productivity/jobs/it jobs/FullStackJob';
import SystemArchitectJob from '../tools/productivity/jobs/it jobs/SystemArchitectJob';
import SolutionArchitectJob from '../tools/productivity/jobs/it jobs/SolutionArchitect';
import AwsJob from '../tools/productivity/jobs/it jobs/AwsJob';
import AzureJob from '../tools/productivity/jobs/it jobs/AzureJob';
import CloudEngineerJob from '../tools/productivity/jobs/it jobs/CloudEngineerJob';
import DotnetJob from '../tools/productivity/jobs/it jobs/DotnetJob';
import NetworkEngineerJob from '../tools/productivity/jobs/it jobs/NetworkEngineerJob';
import CyberSecurityJob from '../tools/productivity/jobs/it jobs/CyberSecurityJob';
import DataScientistJob from '../tools/productivity/jobs/it jobs/DataScientist';
import DesignerJob from '../tools/productivity/jobs/creative jobs/DesignerJob';
import CreativeJob from '../tools/productivity/jobs/creative jobs/CreativeJob';
import GameDesignerJob from '../tools/productivity/jobs/creative jobs/GameDesignerJob';
import WebDesignerJob from '../tools/productivity/jobs/creative jobs/WebDesignerJob';
import ArchitectJob from '../tools/productivity/jobs/creative jobs/ArchitectJob';
import ThreeDJob from '../tools/productivity/jobs/creative jobs/3DJob';
import GraphicDesignerJob from '../tools/productivity/jobs/creative jobs/GraphicDesignerJob';
import FigmaJob from '../tools/productivity/jobs/creative jobs/FigmaJob';
import AdobeJob from '../tools/productivity/jobs/creative jobs/AdobeJob';
import MerchandiseJob from '../tools/productivity/jobs/creative jobs/MerchandiseJob';
import InteriorDesignerJob from '../tools/productivity/jobs/creative jobs/InteriorDesignerJob';
import UiDesignerJob from '../tools/productivity/jobs/creative jobs/UiDesignerJob';
import UxDesignerJob from '../tools/productivity/jobs/creative jobs/UxDesignerJob';
import UiUxJob from '../tools/productivity/jobs/creative jobs/UiUxJob';
import AnimationJob from '../tools/productivity/jobs/creative jobs/AnimationJob';
import VideoEditorJob from '../tools/productivity/jobs/creative jobs/VideoEditorJob';
import MusicJob from '../tools/productivity/jobs/creative jobs/MusicJob';
import AudioJob from '../tools/productivity/jobs/creative jobs/AudioJob';
import FashionDesignerJob from '../tools/productivity/jobs/creative jobs/FashionDesignerJob';
import MarketingJob from '../tools/productivity/jobs/marketing jobs/MarketingJob';
import BrandingJob from '../tools/productivity/jobs/marketing jobs/BrandingJob';
import AdvertisingJob from '../tools/productivity/jobs/marketing jobs/AdvertisingJob';
import InfluencerJob from '../tools/productivity/jobs/marketing jobs/InfluencerJob';
import AffiliateJob from '../tools/productivity/jobs/marketing jobs/AffiliateJob';
import SeoJob from '../tools/productivity/jobs/marketing jobs/SeoJob';
import ContentJob from '../tools/productivity/jobs/marketing jobs/ContentJob';
import FacebookAdsJob from '../tools/productivity/jobs/marketing jobs/FacebookAdsJob';
import GoogleAdsJob from '../tools/productivity/jobs/marketing jobs/GoogleAdsJob';
import EmailJob from '../tools/productivity/jobs/marketing jobs/EmailJob';
import EcommerceJob from '../tools/productivity/jobs/marketing jobs/EcommerceJob';
import VideoProductionJob from '../tools/productivity/jobs/marketing jobs/VideoProductionJob';
import SocialMediaJob from '../tools/productivity/jobs/marketing jobs/SocialMediaJob';
import PublicRelationJob from '../tools/productivity/jobs/marketing jobs/PublicRelationJob';
import WriterJob from '../tools/productivity/jobs/writing jobs/WriterJob';
import WritingJob from '../tools/productivity/jobs/writing jobs/WritingJob';
import ContentWriterJob from '../tools/productivity/jobs/writing jobs/ContentWriterJob';
import ProposalWriterJob from '../tools/productivity/jobs/writing jobs/ProposalWriterJob';
import TechnicalWriterJob from '../tools/productivity/jobs/writing jobs/TechnicalWriterJob';
import MedicalWriterJob from '../tools/productivity/jobs/writing jobs/MedicalWriterJob';
import BusinessWriterJob from '../tools/productivity/jobs/writing jobs/BusinessWriterJob';
import SeoWriterJob from '../tools/productivity/jobs/writing jobs/SeoWriterJob';
import ProofreadingJob from '../tools/productivity/jobs/writing jobs/ProofreadingJob';
import TranslatorJob from '../tools/productivity/jobs/writing jobs/TranslatorJob';
import EducationJob from '../tools/productivity/jobs/education jobs/EducationJob';
import LanguageJob from '../tools/productivity/jobs/education jobs/LanguageJob';
import ProfessionJob from '../tools/productivity/jobs/education jobs/ProfessionJob';
import ResearcherJob from '../tools/productivity/jobs/education jobs/ResearcherJob';

const JobsRouter = () => {
  return (
    <Routes>
      <Route path="/productivity/developer-jobs" element={<SidebarLayout><DeveloperJob /></SidebarLayout>} />
      <Route path="/productivity/web-developer-jobs" element={<SidebarLayout><WebDeveloperJob /></SidebarLayout>} />
      <Route path="/productivity/app-developer-jobs" element={<SidebarLayout><AppDeveloperJob /></SidebarLayout>} />
      <Route path="/productivity/react-js-jobs" element={<SidebarLayout><ReactJs /></SidebarLayout>} />
      <Route path="/productivity/react-native-jobs" element={<SidebarLayout><ReactNativeJob /></SidebarLayout>} />
      <Route path="/productivity/java-jobs" element={<SidebarLayout><JavaJob /></SidebarLayout>} />
      <Route path="/productivity/angular-jobs" element={<SidebarLayout><AngularJob /></SidebarLayout>} />
      <Route path="/productivity/css-jobs" element={<SidebarLayout><CssJob /></SidebarLayout>} />
      <Route path="/productivity/html-jobs" element={<SidebarLayout><HtmlJob /></SidebarLayout>} />
      <Route path="/productivity/next-js-jobs" element={<SidebarLayout><NextJsJob /></SidebarLayout>} />
      <Route path="/productivity/node-js-jobs" element={<SidebarLayout><NodeJsJob /></SidebarLayout>} />
      <Route path="/productivity/wordpress-jobs" element={<SidebarLayout><WordpressJob /></SidebarLayout>} />
      <Route path="/productivity/python-jobs" element={<SidebarLayout><PythonJob /></SidebarLayout>} />
      <Route path="/productivity/data-science-jobs" element={<SidebarLayout><DataScienceJob /></SidebarLayout>} />
      <Route path="/productivity/artificial-intelligence-jobs" element={<SidebarLayout><ArtificialIntelligenceJob /></SidebarLayout>} />
      <Route path="/productivity/deep-learning-jobs" element={<SidebarLayout><DeepLearningJob /></SidebarLayout>} />

      <Route path="/productivity/machine-learning-jobs" element={<SidebarLayout><MachineLearningJob /></SidebarLayout>} />
      <Route path="/productivity/data-analysis-jobs" element={<SidebarLayout><DataAnalysisJob /></SidebarLayout>} />
      <Route path="/productivity/ios-jobs" element={<SidebarLayout><IosJob /></SidebarLayout>} />
      <Route path="/productivity/flutter-jobs" element={<SidebarLayout><FlutterJob /></SidebarLayout>} />
      <Route path="/productivity/android-jobs" element={<SidebarLayout><AndroidJob /></SidebarLayout>} />
      <Route path="/productivity/kotlin-jobs" element={<SidebarLayout><KotlinJob /></SidebarLayout>} />
      <Route path="/productivity/swift-jobs" element={<SidebarLayout><SwiftJob /></SidebarLayout>} />
      <Route path="/productivity/unreal-engine-jobs" element={<SidebarLayout><UnrealEngineJob /></SidebarLayout>} />
      <Route path="/productivity/unity-jobs" element={<SidebarLayout><UnityJob /></SidebarLayout>} />
      <Route path="/productivity/game-developer-jobs" element={<SidebarLayout><GameDeveloperJob /></SidebarLayout>} />
      <Route path="/productivity/database-jobs" element={<SidebarLayout><DatabaseJob /></SidebarLayout>} />
      <Route path="/productivity/sql-jobs" element={<SidebarLayout><SqlJob /></SidebarLayout>} />
      <Route path="/productivity/mysql-jobs" element={<SidebarLayout><MySqlJob /></SidebarLayout>} />
      <Route path="/productivity/mongodb-jobs" element={<SidebarLayout><MongoDBJob /></SidebarLayout>} />
      <Route path="/productivity/qa-engineer-jobs" element={<SidebarLayout><QaEngineerJob /></SidebarLayout>} />
      <Route path="/productivity/testing-jobs" element={<SidebarLayout><TestingJob /></SidebarLayout>} />
      <Route path="/productivity/backend-jobs" element={<SidebarLayout><BackendJob /></SidebarLayout>} />

      <Route path="/productivity/frontend-jobs" element={<SidebarLayout><FrontendJob /></SidebarLayout>} />
      <Route path="/productivity/full-stack-jobs" element={<SidebarLayout><FullStackJob /></SidebarLayout>} />
      <Route path="/productivity/system-architect-jobs" element={<SidebarLayout><SystemArchitectJob/></SidebarLayout>} />
      <Route path="/productivity/solution-architect-jobs" element={<SidebarLayout><SolutionArchitectJob/></SidebarLayout>} />
      <Route path="/productivity/aws-jobs" element={<SidebarLayout><AwsJob/></SidebarLayout>} />
      <Route path="/productivity/azure-jobs" element={<SidebarLayout><AzureJob/></SidebarLayout>} />
      <Route path="/productivity/cloud-engineer-jobs" element={<SidebarLayout><CloudEngineerJob/></SidebarLayout>} />
      <Route path="/productivity/dotnet-jobs" element={<SidebarLayout><DotnetJob/></SidebarLayout>} />
      <Route path="/productivity/network-engineer-jobs" element={<SidebarLayout><NetworkEngineerJob/></SidebarLayout>} />
      <Route path="/productivity/cyber-security-jobs" element={<SidebarLayout><CyberSecurityJob/></SidebarLayout>} />
      <Route path="/productivity/data-scientist-jobs" element={<SidebarLayout><DataScientistJob/></SidebarLayout>} />
      <Route path="/productivity/designer-jobs" element={<SidebarLayout><DesignerJob/></SidebarLayout>} />
      <Route path="/productivity/creative-jobs" element={<SidebarLayout><CreativeJob/></SidebarLayout>} />
      <Route path="/productivity/game-designer-jobs" element={<SidebarLayout><GameDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/web-designer-jobs" element={<SidebarLayout><WebDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/interior-designer-jobs" element={<SidebarLayout><InteriorDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/ui-designer-jobs" element={<SidebarLayout><UiDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/ux-designer-jobs" element={<SidebarLayout><UxDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/ui-ux-jobs" element={<SidebarLayout><UiUxJob/></SidebarLayout>} />

      <Route path="/productivity/architect-jobs" element={<SidebarLayout><ArchitectJob/></SidebarLayout>} />
      <Route path="/productivity/3d-jobs" element={<SidebarLayout><ThreeDJob/></SidebarLayout>} />
      <Route path="/productivity/graphic-designer-jobs" element={<SidebarLayout><GraphicDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/figma-jobs" element={<SidebarLayout><FigmaJob/></SidebarLayout>} />
      <Route path="/productivity/adobe-jobs" element={<SidebarLayout><AdobeJob/></SidebarLayout>} />
      <Route path="/productivity/merchandise-jobs" element={<SidebarLayout><MerchandiseJob/></SidebarLayout>} />
      <Route path="/productivity/animation-jobs" element={<SidebarLayout><AnimationJob/></SidebarLayout>} />
      <Route path="/productivity/video-editor-jobs" element={<SidebarLayout><VideoEditorJob/></SidebarLayout>} />
      <Route path="/productivity/music-jobs" element={<SidebarLayout><MusicJob/></SidebarLayout>} />
      <Route path="/productivity/audio-jobs" element={<SidebarLayout><AudioJob/></SidebarLayout>} />
      <Route path="/productivity/fashion-designer-jobs" element={<SidebarLayout><FashionDesignerJob/></SidebarLayout>} />
      <Route path="/productivity/marketing-jobs" element={<SidebarLayout><MarketingJob/></SidebarLayout>} />
      <Route path="/productivity/branding-jobs" element={<SidebarLayout><BrandingJob/></SidebarLayout>} />
      <Route path="/productivity/advertising-jobs" element={<SidebarLayout><AdvertisingJob/></SidebarLayout>} />
      <Route path="/productivity/facebook-ads-jobs" element={<SidebarLayout><FacebookAdsJob/></SidebarLayout>} />
      <Route path="/productivity/google-ads-jobs" element={<SidebarLayout><GoogleAdsJob/></SidebarLayout>} />
      <Route path="/productivity/social-media-jobs" element={<SidebarLayout><SocialMediaJob/></SidebarLayout>} />
      <Route path="/productivity/public-relation-jobs" element={<SidebarLayout><PublicRelationJob/></SidebarLayout>} />


      <Route path="/productivity/influencer-jobs" element={<SidebarLayout><InfluencerJob/></SidebarLayout>} />
      <Route path="/productivity/affiliate-jobs" element={<SidebarLayout><AffiliateJob/></SidebarLayout>} />
      <Route path="/productivity/seo-jobs" element={<SidebarLayout><SeoJob/></SidebarLayout>} />
      <Route path="/productivity/content-jobs" element={<SidebarLayout><ContentJob/></SidebarLayout>} />
      <Route path="/productivity/email-jobs" element={<SidebarLayout><EmailJob/></SidebarLayout>} />
      <Route path="/productivity/ecommerce-jobs" element={<SidebarLayout><EcommerceJob/></SidebarLayout>} />
      <Route path="/productivity/video-production-jobs" element={<SidebarLayout><VideoProductionJob/></SidebarLayout>} />
      <Route path="/productivity/writer-jobs" element={<SidebarLayout><WriterJob/></SidebarLayout>} />
      <Route path='/productivity/writing-jobs' element={<SidebarLayout><WritingJob/></SidebarLayout>} />
      <Route path='/productivity/content-writer-jobs' element={<SidebarLayout><ContentWriterJob/></SidebarLayout>} />
      <Route path='/productivity/proposal-writer-jobs' element={<SidebarLayout><ProposalWriterJob/></SidebarLayout>} />
      <Route path='/productivity/technical-writer-jobs' element={<SidebarLayout><TechnicalWriterJob/></SidebarLayout>} />
      <Route path='/productivity/medical-writer-jobs' element={<SidebarLayout><MedicalWriterJob/></SidebarLayout>} />
      <Route path='/productivity/business-writer-jobs' element={<SidebarLayout><BusinessWriterJob/></SidebarLayout>} />
      <Route path='/productivity/seo-writer-jobs' element={<SidebarLayout><SeoWriterJob/></SidebarLayout>} />
      <Route path='/productivity/proofreading-jobs' element={<SidebarLayout><ProofreadingJob/></SidebarLayout>} />
      <Route path='/productivity/translator-jobs' element={<SidebarLayout><TranslatorJob/></SidebarLayout>} />

      <Route path='/productivity/education-jobs' element={<SidebarLayout><EducationJob/></SidebarLayout>} />
      <Route path='/productivity/language-jobs' element={<SidebarLayout><LanguageJob/></SidebarLayout>} />
      <Route path='/productivity/profession-jobs' element={<SidebarLayout><ProfessionJob/></SidebarLayout>} />
      <Route path='/productivity/researcher-jobs' element={<SidebarLayout><ResearcherJob/></SidebarLayout>} />
    </Routes>
  );
};

export default JobsRouter;
