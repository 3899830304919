import React, { useEffect } from 'react'
import classes from "./styles/RightSidebar.module.css";
import { RightSideWiraaAds } from './Ads/RightSideWiraaAds';
import LeftSidebar from './LeftSidebar';
const RightSidebar = () => {
  
    useEffect(() => {
      // Initialize Google Ads
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
        console.error("AdSense run successfully");
      } catch (e) {
        console.error("AdSense error", e);
      }
    }, []);

  return (
    <div className={classes.container}>
      {/* <div className={classes.ads}>
      <ins class="adsbygoogle"
     style={{display:"block"}}
     data-ad-client="ca-pub-4348412345789711"
     data-ad-slot="4525470961"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
    </div>
    <div className={classes.ads}>
    <ins class="adsbygoogle"
     style={{display:"block"}}
     data-ad-format="autorelaxed"
     data-ad-client="ca-pub-4348412345789711"
     data-ad-slot="2538306146"></ins>
    </div> */}

    <RightSideWiraaAds/>
    <div className={classes.leftSidebar}>
      <LeftSidebar/>
    </div>
     
    </div>
  )
}

export default RightSidebar;
