import React, { useState } from 'react'
import classes from "./styles/LeftSidebar.module.css";
import downImg from "../assets/next.png";
import { NavLink } from 'react-router-dom';

const LeftSidebar = () => {
    // const [box1 , setBox1] = useState(false);
    // const [box2 , setBox2] = useState(false);
    // const [box3 , setBox3] = useState(false);
    const [box4 , setBox4] = useState(false);
    const [box5 , setBox5] = useState(false);
    const [box6 , setBox6] = useState(false);
    const [box7 , setBox7] = useState(false);

    // const handlebox1 = () => {
    //     setBox1(!box1);
    // }
    // const handlebox2 = () => {
    //     setBox2(!box2);
    // }

    // const handlebox3 = () => {
    //     setBox3(!box3);
    // }
    const handlebox4 = () => {
        setBox4(!box4);
    }
    const handlebox5 = () => {
        setBox5(!box5);
    }
    const handlebox6 = () => {
        setBox6(!box6);
    }
    const handlebox7 = () => {
        setBox7(!box7);
    }

  return (
    <div className={classes.container}>
        <h2 className={classes.title}>Tools</h2>
       {/* <div className={box1 ? classes.boxOpen : classes.box} >
            <div className={classes.upperCon} onClick={handlebox1}>
                <div className={`${classes.logoCon} ${box1 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div>Design Tools</div>
            </div>
            <div className={classes.lowerCon}>
                <div>
                </div>
            </div>
        </div>
        <div className={box2 ? classes.boxOpen : classes.box} >
            <div className={classes.upperCon} onClick={handlebox2}>
                <div className={`${classes.logoCon} ${box2 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div>Web Tools</div>
            </div>
            <div className={classes.lowerCon}>
                <div></div>
            </div>
        </div>
        <div className={box3 ? classes.boxOpen : classes.box}>
            <div className={classes.upperCon} onClick={handlebox3}>
                <div className={`${classes.logoCon} ${box3 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div>PDF Tools</div>
            </div>
            <div className={classes.lowerCon}>
                <div></div>
            </div>
        </div>*/}
        <div className={box4 ? classes.boxOpen : classes.box} >
            <div className={classes.upperCon} onClick={handlebox4}>
                <div className={`${classes.logoCon} ${box4 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div className={classes.titles}>TEXT</div>
            </div>
            <div className={classes.lowerCon}>
                <div>
                <NavLink className={classes.subCategory}>Counters</NavLink>
                <NavLink to="/text-tools/word-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Word Counter</NavLink>
                <NavLink to="/text-tools/character-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Character Counter</NavLink>
                <NavLink to="/text-tools/line-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Line Counter</NavLink>
                <NavLink to="/text-tools/letter-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Letter Counter</NavLink>

                <NavLink className={classes.subCategory}>Case Converters</NavLink>
                <NavLink to="/text-tools/uppercase" className={({ isActive }) => (isActive ? classes.active : "")}>Uppercase Converter</NavLink>
                <NavLink to="/text-tools/lowercase" className={({ isActive }) => (isActive ? classes.active : "")}>Lowercase Converter</NavLink>
                <NavLink to="/text-tools/capitalise" className={({ isActive }) => (isActive ? classes.active : "")}>Capitalise Converter</NavLink>
                </div>
            </div>
        </div>
        
        <div className={box5 ? classes.boxOpen : classes.box} >
            <div className={classes.upperCon} onClick={handlebox5}>
                <div className={`${classes.logoCon} ${box5 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div className={classes.titles}>PRODUCTIVITY</div>
            </div>
            <div className={classes.lowerCon}>
                <div>
                <NavLink className={classes.subCategory}>Job Finders</NavLink>
                <NavLink to="/productivity/developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Developer Job Finder</NavLink>
                <NavLink to="/productivity/web-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Web Developer Job Finder</NavLink>
                <NavLink to="/productivity/app-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>App Developer Job Finder</NavLink>
                <NavLink to="/productivity/react-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>React Js Job Finder</NavLink>
                <NavLink to="/productivity/react-native-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>React Native Job Finder</NavLink>
                <NavLink to="/productivity/java-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Java Job Finder</NavLink>
                <NavLink to="/productivity/angular-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Angular Job Finder</NavLink>
                <NavLink to="/productivity/css-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>CSS Job Finder</NavLink>
                <NavLink to="/productivity/html-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Html Job Finder</NavLink>
                <NavLink to="/productivity/next-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Next Js Job Finder</NavLink>
                <NavLink to="/productivity/node-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Node Js Job Finder</NavLink>
                <NavLink to="/productivity/wordpress-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Wordpress Job Finder</NavLink>
                <NavLink to="/productivity/python-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Python Job Finder</NavLink>
                <NavLink to="/productivity/data-science-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Data Science Job Finder</NavLink>
                <NavLink to="/productivity/artificial-intelligence-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Artificial Intelligence Job Finder</NavLink>
                <NavLink to="/productivity/deep-learning-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Deep Learning Job Finder</NavLink>
                <NavLink to="/productivity/machine-learning-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Machine Learning Job Finder</NavLink>
                <NavLink to="/productivity/data-analysis-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Data Analysis Job Finder</NavLink>
                <NavLink to="/productivity/ios-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>IOS Job Finder</NavLink>
                <NavLink to="/productivity/flutter-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Flutter Job Finder</NavLink>
                <NavLink to="/productivity/android-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Android Job Finder</NavLink>
                <NavLink to="/productivity/kotlin-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Kotlin Job Finder</NavLink>
                <NavLink to="/productivity/swift-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Swift Job Finder</NavLink>
                <NavLink to="/productivity/unreal-engine-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Unreal Engine Job Finder</NavLink>
                <NavLink to="/productivity/unity-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Unity Job Finder</NavLink>
                <NavLink to="/productivity/game-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Game Developer Job Finder</NavLink>
                <NavLink to="/productivity/database-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Database Job Finder</NavLink>
                <NavLink to="/productivity/sql-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>SQL Job Finder</NavLink>
                <NavLink to="/productivity/mysql-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>MySQL Job Finder</NavLink>
                <NavLink to="/productivity/mongodb-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>MongoDB Job Finder</NavLink>
                <NavLink to="/productivity/qa-engineer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>QA Engineer Job Finder</NavLink>
                <NavLink to="/productivity/testing-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Testing Job Finder</NavLink>
                <NavLink to="/productivity/backend-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Backend Job Finder</NavLink>
                <NavLink to="/productivity/frontend-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Frontend Job Finder</NavLink>
                <NavLink to="/productivity/full-stack-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Full Stack Job Finder</NavLink>
                <NavLink to="/productivity/system-architect-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>System Architect Job Finder</NavLink>
                <NavLink to="/productivity/solution-architect-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Solution Architect Job Finder</NavLink>
                <NavLink to="/productivity/aws-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>AWS Job Finder</NavLink>
                <NavLink to="/productivity/azure-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Azure Job Finder</NavLink>
                <NavLink to="/productivity/cloud-engineer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Cloud Engineer Job Finder</NavLink>
                <NavLink to="/productivity/dotnet-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>DotNet Job Finder</NavLink>
                <NavLink to="/productivity/network-engineer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Network Engineer Job Finder</NavLink>
                <NavLink to="/productivity/cyber-security-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Cyber Security Job Finder</NavLink>
                <NavLink to="/productivity/data-scientist-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Data Scientist Job Finder</NavLink>

                <NavLink to="/productivity/designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Designer Job Finder</NavLink>
                <NavLink to="/productivity/creative-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Creative Job Finder</NavLink>
                <NavLink to="/productivity/game-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Game Designer Job Finder</NavLink>
                <NavLink to="/productivity/web-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Web Designer Job Finder</NavLink>
                <NavLink to="/productivity/interior-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Interior Designer Job Finder</NavLink>
                <NavLink to="/productivity/ui-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>UI Designer Job Finder</NavLink>
                <NavLink to="/productivity/ux-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>UX Designer Job Finder</NavLink>
                <NavLink to="/productivity/ui-ux-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>UI/UX Job Finder</NavLink>
                <NavLink to="/productivity/architect-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Architect Job Finder</NavLink>
                <NavLink to="/productivity/3d-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>3D Job Finder</NavLink>
                <NavLink to="/productivity/graphic-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Graphic Designer Job Finder</NavLink>
                <NavLink to="/productivity/figma-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Figma Job Finder</NavLink>
                <NavLink to="/productivity/adobe-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Adobe Job Finder</NavLink>
                <NavLink to="/productivity/merchandise-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Merchandise Job Finder</NavLink>
                <NavLink to="/productivity/animation-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Animation Job Finder</NavLink>
                <NavLink to="/productivity/video-editor-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Video Editor Job Finder</NavLink>
                <NavLink to="/productivity/music-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Music Job Finder</NavLink>
                <NavLink to="/productivity/audio-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Audio Job Finder</NavLink>
                <NavLink to="/productivity/fashion-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Fashion Designer Job Finder</NavLink>
                
                <NavLink to="/productivity/marketing-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Marketing Job Finder</NavLink>
                <NavLink to="/productivity/branding-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Branding Job Finder</NavLink>
                <NavLink to="/productivity/advertising-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Advertising Job Finder</NavLink>
                <NavLink to="/productivity/facebook-ads-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Facebook Ads Job Finder</NavLink>
                <NavLink to="/productivity/google-ads-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Google Ads Job Finder</NavLink>
                <NavLink to="/productivity/social-media-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Social Media Job Finder</NavLink>
                <NavLink to="/productivity/public-relation-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Public Relation Job Finder</NavLink>
                <NavLink to="/productivity/influencer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Influencer Job Finder</NavLink>
                <NavLink to="/productivity/affiliate-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Affiliate Job Finder</NavLink>
                <NavLink to="/productivity/seo-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>SEO Job Finder</NavLink>
                <NavLink to="/productivity/content-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Content Job Finder</NavLink>
                <NavLink to="/productivity/email-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Email Job Finder</NavLink>
                <NavLink to="/productivity/ecommerce-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>eCommerce Job Finder</NavLink>
                <NavLink to="/productivity/video-production-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Video Production Job Finder</NavLink>

                <NavLink to="/productivity/writer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Writer Job Finder</NavLink>
                <NavLink to="/productivity/writing-jobs" className={({isActive}) => (isActive ? classes.active : "")}>Writing Job Finder</NavLink>
                <NavLink to='/productivity/content-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Content Writer Job Finder</NavLink>
                <NavLink to='/productivity/proposal-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Proposal Writer Job Finder</NavLink>
                <NavLink to='/productivity/technical-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Technical Writer Job Finder</NavLink>
                <NavLink to='/productivity/medical-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Medical Writer Job Finder</NavLink>
                <NavLink to='/productivity/business-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Business Writer Job Finder</NavLink>
                <NavLink to='/productivity/seo-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>SEO Writer Job Finder</NavLink>
                <NavLink to='/productivity/proofreading-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Proofreading Job Finder</NavLink>
                <NavLink to='/productivity/translator-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Translator Job Finder</NavLink>

                <NavLink to='/productivity/education-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Education Job Finder</NavLink>
                <NavLink to='/productivity/language-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Language Job Finder</NavLink>
                <NavLink to='/productivity/profession-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Profession Job Finder</NavLink>
                <NavLink to='/productivity/researcher-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Researcher Job Finder</NavLink>
                </div>
            </div>
        </div>
        <div className={box6 ? classes.boxOpen : classes.box} >
            <div className={classes.upperCon} onClick={handlebox6}>
                <div className={`${classes.logoCon} ${box6 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div className={classes.titles}>CALCULATORS</div>
            </div>
            <div className={classes.lowerCon}>
                <div>
                    <NavLink className={classes.subCategory}>Time</NavLink>
                    <NavLink to="/calculators/age-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Age Calculator</NavLink>
                    <NavLink to="/calculators/birthday-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Birthday Calculator</NavLink>
                    <NavLink to="/calculators/date-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Date Calculator</NavLink>

                    <NavLink className={classes.subCategory}>Fitness</NavLink>
                    <NavLink to="/calculators/bmi-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>BMI Calculator</NavLink>
                    <NavLink to="/calculators/calories-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Calories Calculator</NavLink>

                    <NavLink className={classes.subCategory}>Professional</NavLink>
                    <NavLink to="/calculators/hourly-to-salary-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Hourly To Salary Calculator</NavLink>
                </div>
            </div>
        </div> 

        <div className={box7 ? classes.boxOpen : classes.box}>
            <div className={classes.upperCon} onClick={handlebox7}>
                <div className={`${classes.logoCon} ${box7 ? classes.rotateIcon : ''}`}><img src={downImg} alt='plus-minus-icon'/></div>
                <div className={classes.titles}>CONVERTERS</div>
            </div>
            <div className={classes.lowerCon}>
                <div>
                    <NavLink className={classes.subCategory}>Number Converters</NavLink>
                    <NavLink to="/converters/binary-to-decimal" className={({ isActive }) => (isActive ? classes.active : "")}>Binary to decimal</NavLink>
                    <NavLink to="/converters/decimal-to-binary" className={({ isActive }) => (isActive ? classes.active : "")}> Decimal to binary</NavLink>
                    <NavLink className={classes.subCategory}>Temperature Converters</NavLink>
                    <NavLink to="/converters/celsius-to-fahrenheit" className={({ isActive }) => (isActive ? classes.active : "")}>Celsius to fahrenheit</NavLink>
                    <NavLink to="/converters/celsius-to-kelvin" className={({ isActive }) => (isActive ? classes.active : "")}>Celsius to kelvin</NavLink>
                    <NavLink to="/converters/fahrenheit-to-celsius" className={({ isActive }) => (isActive ? classes.active : "")}>Fahrenheit to celsius</NavLink>
                    <NavLink to="/converters/fahrenheit-to-kelvin" className={({ isActive }) => (isActive ? classes.active : "")}>Fahrenheit to kelvin</NavLink>
                    <NavLink to="/converters/kelvin-to-celsius" className={({ isActive }) => (isActive ? classes.active : "")}>Kelvin to celsius</NavLink>
                    <NavLink to="/converters/kelvin-to-fahrenheit" className={({ isActive }) => (isActive ? classes.active : "")}>Kelvin to fahrenheit</NavLink>

                    <NavLink className={classes.subCategory}>Weight Converters</NavLink>
                    <NavLink to="/converters/microgram-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to milligram</NavLink>
                    <NavLink to="/converters/microgram-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to gram</NavLink>
                    <NavLink to="/converters/microgram-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to kilogram</NavLink>
                    <NavLink to="/converters/microgram-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to metric ton</NavLink>
                    <NavLink to="/converters/microgram-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to ounce</NavLink>
                    <NavLink to="/converters/microgram-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to pound</NavLink>
                    <NavLink to="/converters/microgram-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to stone</NavLink>
                    <NavLink to="/converters/microgram-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to ton</NavLink>

                    <NavLink to="/converters/milligram-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to microgram</NavLink>
                    <NavLink to="/converters/milligram-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to gram</NavLink>
                    <NavLink to="/converters/milligram-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to kilogram</NavLink>
                    <NavLink to="/converters/milligram-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to metric ton</NavLink>
                    <NavLink to="/converters/milligram-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to ounce</NavLink>
                    <NavLink to="/converters/milligram-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to pound</NavLink>
                    <NavLink to="/converters/milligram-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to stone</NavLink>
                    <NavLink to="/converters/milligram-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to ton</NavLink>
                    
                    <NavLink to="/converters/gram-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to microgram</NavLink>
                    <NavLink to="/converters/gram-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to milligram</NavLink>
                    <NavLink to="/converters/gram-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to kilogram</NavLink>
                    <NavLink to="/converters/gram-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to metricton</NavLink>
                    <NavLink to="/converters/gram-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to ounce</NavLink>
                    <NavLink to="/converters/gram-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to pound</NavLink>
                    <NavLink to="/converters/gram-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to stone</NavLink>
                    <NavLink to="/converters/gram-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to ton</NavLink>

                    <NavLink to="/converters/kilogram-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to microgram</NavLink>
                    <NavLink to="/converters/kilogram-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to milligram</NavLink>
                    <NavLink to="/converters/kilogram-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to gram</NavLink>
                    <NavLink to="/converters/kilogram-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to metric ton</NavLink>
                    <NavLink to="/converters/kilogram-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to ounce</NavLink>
                    <NavLink to="/converters/kilogram-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to pound</NavLink>
                    <NavLink to="/converters/kilogram-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to stone</NavLink>
                    <NavLink to="/converters/kilogram-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Kilogram to ton</NavLink>

                    <NavLink to="/converters/metricton-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to microgram</NavLink>
                    <NavLink to="/converters/metricton-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to milligram</NavLink>
                    <NavLink to="/converters/metricton-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to gram</NavLink>
                    <NavLink to="/converters/metricton-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to kilogram</NavLink>
                    <NavLink to="/converters/metricton-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to ounce</NavLink>
                    <NavLink to="/converters/metricton-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to pound</NavLink>
                    <NavLink to="/converters/metricton-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to stone</NavLink>
                    <NavLink to="/converters/metricton-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Metric Ton to ton</NavLink>

                    <NavLink to="/converters/ounce-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to microgram</NavLink>
                    <NavLink to="/converters/ounce-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to milligram</NavLink>
                    <NavLink to="/converters/ounce-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to gram</NavLink>
                    <NavLink to="/converters/ounce-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to kilogram</NavLink>
                    <NavLink to="/converters/ounce-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to metricton</NavLink>
                    <NavLink to="/converters/ounce-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to pound</NavLink>
                    <NavLink to="/converters/ounce-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to stone</NavLink>
                    <NavLink to="/converters/ounce-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Ounce to ton</NavLink>

                    <NavLink to="/converters/pound-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to microgram</NavLink>
                    <NavLink to="/converters/pound-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to milligram</NavLink>
                    <NavLink to="/converters/pound-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to gram</NavLink>
                    <NavLink to="/converters/pound-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to kilogram</NavLink>
                    <NavLink to="/converters/pound-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to metricton</NavLink>
                    <NavLink to="/converters/pound-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to ounce</NavLink>
                    <NavLink to="/converters/pound-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to stone</NavLink>
                    <NavLink to="/converters/pound-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Pound to ton</NavLink>

                    <NavLink to="/converters/stone-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to microgram</NavLink>
                    <NavLink to="/converters/stone-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to milligram</NavLink>
                    <NavLink to="/converters/stone-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to gram</NavLink>
                    <NavLink to="/converters/stone-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to kilogram</NavLink>
                    <NavLink to="/converters/stone-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to metricton</NavLink>
                    <NavLink to="/converters/stone-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to ounce</NavLink>
                    <NavLink to="/converters/stone-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to pound</NavLink>
                    <NavLink to="/converters/stone-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Stone to ton</NavLink>

                    <NavLink to="/converters/ton-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to microgram</NavLink>
                    <NavLink to="/converters/ton-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to milligram</NavLink>
                    <NavLink to="/converters/ton-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to gram</NavLink>
                    <NavLink to="/converters/ton-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to kilogram</NavLink>
                    <NavLink to="/converters/ton-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to metricton</NavLink>
                    <NavLink to="/converters/ton-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to ounce</NavLink>
                    <NavLink to="/converters/ton-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to pound</NavLink>
                    <NavLink to="/converters/ton-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Ton to stone</NavLink>
                </div>
            </div>
        </div>
    </div>
  )
}

export default LeftSidebar
