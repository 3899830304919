import React from 'react'
import classes from "./styles/Converters.module.css";
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";

const Productivity = () => {
  return (
    <div className={classes.Container}>
        <Helmet>
                <title>Top Productivity Tools to Maximize Efficiency and Streamline Your Workflow</title>
                <meta name="description" content="Discover the best productivity tools to boost your efficiency, improve your workflow, and achieve more every day." />
        </Helmet>
        <h1 className={classes.title}>Productivity Tools</h1>
        <p className={classes.subTitle}>Explore the Best Productivity Tools to Enhance Your Workflow and Maximize Efficiency Every Day.</p>
        <h3 className={classes.subHeadings}>Job Finders</h3>
        <div className={classes.toolsContainer}>
                <NavLink to="/productivity/developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Developer Job Finder</NavLink>
                <NavLink to="/productivity/web-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Web Developer Job Finder</NavLink>
                <NavLink to="/productivity/app-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>App Developer Job Finder</NavLink>
                <NavLink to="/productivity/react-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>React Js Job Finder</NavLink>
                <NavLink to="/productivity/react-native-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>React Native Job Finder</NavLink>
                <NavLink to="/productivity/java-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Java Job Finder</NavLink>
                <NavLink to="/productivity/angular-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Angular Job Finder</NavLink>
                <NavLink to="/productivity/css-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>CSS Job Finder</NavLink>
                <NavLink to="/productivity/html-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Html Job Finder</NavLink>
                <NavLink to="/productivity/next-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Next Js Job Finder</NavLink>
                <NavLink to="/productivity/node-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Node Js Job Finder</NavLink>
                <NavLink to="/productivity/wordpress-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Wordpress Job Finder</NavLink>
                <NavLink to="/productivity/python-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Python Job Finder</NavLink>
                <NavLink to="/productivity/data-science-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Data Science Job Finder</NavLink>
                <NavLink to="/productivity/artificial-intelligence-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Artificial Intelligence Job Finder</NavLink>
                <NavLink to="/productivity/deep-learning-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Deep Learning Job Finder</NavLink>
                <NavLink to="/productivity/machine-learning-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Machine Learning Job Finder</NavLink>
                <NavLink to="/productivity/data-analysis-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Data Analysis Job Finder</NavLink>
                <NavLink to="/productivity/ios-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>IOS Job Finder</NavLink>
                <NavLink to="/productivity/flutter-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Flutter Job Finder</NavLink>
                <NavLink to="/productivity/android-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Android Job Finder</NavLink>
                <NavLink to="/productivity/kotlin-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Kotlin Job Finder</NavLink>
                <NavLink to="/productivity/swift-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Swift Job Finder</NavLink>
                <NavLink to="/productivity/unreal-engine-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Unreal Engine Job Finder</NavLink>
                <NavLink to="/productivity/unity-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Unity Job Finder</NavLink>
                <NavLink to="/productivity/game-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Game Developer Job Finder</NavLink>
                <NavLink to="/productivity/database-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Database Job Finder</NavLink>
                <NavLink to="/productivity/sql-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>SQL Job Finder</NavLink>
                <NavLink to="/productivity/mysql-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>MySQL Job Finder</NavLink>
                <NavLink to="/productivity/mongodb-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>MongoDB Job Finder</NavLink>
                <NavLink to="/productivity/qa-engineer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>QA Engineer Job Finder</NavLink>
                <NavLink to="/productivity/testing-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Testing Job Finder</NavLink>
                <NavLink to="/productivity/backend-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Backend Job Finder</NavLink>
                <NavLink to="/productivity/frontend-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Frontend Job Finder</NavLink>
                <NavLink to="/productivity/full-stack-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Full Stack Job Finder</NavLink>
                <NavLink to="/productivity/system-architect-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>System Architect Job Finder</NavLink>
                <NavLink to="/productivity/solution-architect-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Solution Architect Job Finder</NavLink>
                <NavLink to="/productivity/aws-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>AWS Job Finder</NavLink>
                <NavLink to="/productivity/azure-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Azure Job Finder</NavLink>
                <NavLink to="/productivity/cloud-engineer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Cloud Engineer Job Finder</NavLink>
                <NavLink to="/productivity/dotnet-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>DotNet Job Finder</NavLink>
                <NavLink to="/productivity/network-engineer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Network Engineer Job Finder</NavLink>
                <NavLink to="/productivity/cyber-security-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Cyber Security Job Finder</NavLink>
                <NavLink to="/productivity/data-scientist-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Data Scientist Job Finder</NavLink>

                <NavLink to="/productivity/designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Designer Job Finder</NavLink>
                <NavLink to="/productivity/creative-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Creative Job Finder</NavLink>
                <NavLink to="/productivity/game-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Game Designer Job Finder</NavLink>
                <NavLink to="/productivity/web-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Web Designer Job Finder</NavLink>
                <NavLink to="/productivity/interior-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Interior Designer Job Finder</NavLink>
                <NavLink to="/productivity/ui-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>UI Designer Job Finder</NavLink>
                <NavLink to="/productivity/ux-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>UX Designer Job Finder</NavLink>
                <NavLink to="/productivity/ui-ux-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>UI/UX Job Finder</NavLink>
                <NavLink to="/productivity/architect-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Architect Job Finder</NavLink>
                <NavLink to="/productivity/3d-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>3D Job Finder</NavLink>
                <NavLink to="/productivity/graphic-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Graphic Designer Job Finder</NavLink>
                <NavLink to="/productivity/figma-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Figma Job Finder</NavLink>
                <NavLink to="/productivity/adobe-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Adobe Job Finder</NavLink>
                <NavLink to="/productivity/merchandise-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Merchandise Job Finder</NavLink>
                <NavLink to="/productivity/animation-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Animation Job Finder</NavLink>
                <NavLink to="/productivity/video-editor-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Video Editor Job Finder</NavLink>
                <NavLink to="/productivity/music-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Music Job Finder</NavLink>
                <NavLink to="/productivity/audio-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Audio Job Finder</NavLink>
                <NavLink to="/productivity/fashion-designer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Fashion Designer Job Finder</NavLink>
                
                <NavLink to="/productivity/marketing-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Marketing Job Finder</NavLink>
                <NavLink to="/productivity/branding-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Branding Job Finder</NavLink>
                <NavLink to="/productivity/advertising-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Advertising Job Finder</NavLink>
                <NavLink to="/productivity/facebook-ads-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Facebook Ads Job Finder</NavLink>
                <NavLink to="/productivity/google-ads-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Google Ads Job Finder</NavLink>
                <NavLink to="/productivity/social-media-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Social Media Job Finder</NavLink>
                <NavLink to="/productivity/public-relation-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Public Relation Job Finder</NavLink>
                <NavLink to="/productivity/influencer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Influencer Job Finder</NavLink>
                <NavLink to="/productivity/affiliate-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Affiliate Job Finder</NavLink>
                <NavLink to="/productivity/seo-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>SEO Job Finder</NavLink>
                <NavLink to="/productivity/content-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Content Job Finder</NavLink>
                <NavLink to="/productivity/email-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Email Job Finder</NavLink>
                <NavLink to="/productivity/ecommerce-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>eCommerce Job Finder</NavLink>
                <NavLink to="/productivity/video-production-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Video Production Job Finder</NavLink>

                <NavLink to="/productivity/writer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Writer Job Finder</NavLink>
                <NavLink to="/productivity/writing-jobs" className={({isActive}) => (isActive ? classes.active : "")}>Writing Job Finder</NavLink>
                <NavLink to='/productivity/content-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Content Writer Job Finder</NavLink>
                <NavLink to='/productivity/proposal-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Proposal Writer Job Finder</NavLink>
                <NavLink to='/productivity/technical-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Technical Writer Job Finder</NavLink>
                <NavLink to='/productivity/medical-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Medical Writer Job Finder</NavLink>
                <NavLink to='/productivity/business-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Business Writer Job Finder</NavLink>
                <NavLink to='/productivity/seo-writer-jobs' className={({isActive}) => (isActive ? classes.active : "")}>SEO Writer Job Finder</NavLink>
                <NavLink to='/productivity/proofreading-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Proofreading Job Finder</NavLink>
                <NavLink to='/productivity/translator-jobs' className={({isActive}) => (isActive ? classes.active : "")}>Translator Job Finder</NavLink>
        </div>  
    </div>
  )
}

export default Productivity;
