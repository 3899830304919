import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import React, { Suspense } from 'react';

// Layouts
import FullwidthLayout from './layouts/FullwidthLayout';
import SidebarLayout from './layouts/SidebarLayout';
import HourlytoSalaryCalculator from './tools/calculators/professional/HourlytoSalaryConverter';
import Home from './pages/Home';
import JobsRouter from './routers/JobsRouter';
import redirectRoutes from './routers/Redirect.json';
import Productivity from './pages/Productivity';
import ScrollToTop from './routers/ScrollToTop';

// Pages
const Converters = React.lazy(() => import("./pages/Converters"));
const TextTools = React.lazy(() => import('./pages/TextTools'));
const Calculators = React.lazy(() => import('./pages/Calculators'));

// Lazy-loaded components

// Text tools 
const WordCounter = React.lazy(() => import('./tools/text-tools/text-counters/WordCounter'));
const CharacterCounter = React.lazy(() => import('./tools/text-tools/text-counters/CharacterCounter'));
const LineCounter = React.lazy(() => import('./tools/text-tools/text-counters/LineCounter'));
const LetterCounter = React.lazy(() => import('./tools/text-tools/text-counters/LetterCounter'));
const UppercaseConverter = React.lazy(() => import('./tools/text-tools/text-converters/UppercaseConverter'));
const LowercaseConverter = React.lazy(() => import('./tools/text-tools/text-converters/LowercaseConverter'));
const CapitaliseConverter = React.lazy(() => import('./tools/text-tools/text-converters/CapitaliseConverter'));

//Calculators 
const AgeCalculator = React.lazy(() => import('./tools/calculators/time/AgeCalculator')); 
const BirthdayCalculator = React.lazy(() => import('./tools/calculators/time/BirthdayCalculator'));
const DateCalculator = React.lazy(() => import('./tools/calculators/time/DateCalculator'));
const BmiCalculator = React.lazy(() => import('./tools/calculators/fitness/BmiCalculator'));
const CaloriesCalculator = React.lazy(() => import('./tools/calculators/fitness/CaloriesCalculator'));

//Number Converters
const BinarytoDecimal = React.lazy(() => import('./tools/converters/number-converter/BinarytoDecimal'));
const DecimaltoBinary = React.lazy(() => import('./tools/converters/number-converter/DecimaltoBinary'));
const CelsiustoFahrenheit = React.lazy(() => import('./tools/converters/temperature-converter/CelsiustoFahrenheit'));
const CelsiustoKelvin = React.lazy(() => import('./tools/converters/temperature-converter/CelsiustoKelvin'));
const FahrenheittoCelsius = React.lazy(() => import('./tools/converters/temperature-converter/FahrenheittoCelsius'));
const FahrenheittoKelvin = React.lazy(() => import('./tools/converters/temperature-converter/FahrenheittoKelvin'));
const KelvintoCelsius = React.lazy(() => import('./tools/converters/temperature-converter/KelvintoCelsius'));
const KelvintoFahrenheit = React.lazy(() => import('./tools/converters/temperature-converter/KelvintoFahrenheit'));

// Weight Converters
const MicrogramtoMilligram = React.lazy(() => import('./tools/converters/weight-converter/microgram/MicrogramtoMilligram'));
const MicrogramtoGram = React.lazy(() => import('./tools/converters/weight-converter/microgram/MicrogramtoGram'));
const MicrogramtoKilogram = React.lazy(() => import('./tools/converters/weight-converter/microgram/MicrogramtoKilogram'));
const MicrogramtoMetricton = React.lazy(() => import('./tools/converters/weight-converter/microgram/MicrogramtoMetricton'));
const MicrogramtoOunce = React.lazy(() => import('./tools/converters/weight-converter/microgram/MicrogramtoOunce'));
const MicrogramtoPound = React.lazy(() => import('./tools/converters/weight-converter/microgram/MicrogramtoPound'));
const MicrogramtoStone = React.lazy(() => import('./tools/converters/weight-converter/microgram/MicrogramtoStone'));
const MicrogramtoTon = React.lazy(() => import('./tools/converters/weight-converter/microgram/MicrogramtoTon'));
const MilligramtoMicrogram = React.lazy(() => import('./tools/converters/weight-converter/milligram/MilligramtoMicrogram'));
const MilligramtoGram = React.lazy(() => import('./tools/converters/weight-converter/milligram/MilligramtoGram'));
const MilligramtoKilogram = React.lazy(() => import('./tools/converters/weight-converter/milligram/MilligramtoKilogram'));
const MilligramtoMetricton = React.lazy(() => import('./tools/converters/weight-converter/milligram/MilligramtoMetricton'));
const MilligramtoOunce = React.lazy(() => import('./tools/converters/weight-converter/milligram/MilligramtoOunce'));
const MilligramtoPound = React.lazy(() => import('./tools/converters/weight-converter/milligram/MilligramtoPound'));
const MilligramtoStone = React.lazy(() => import('./tools/converters/weight-converter/milligram/MilligramtoStone'));
const MilligramtoTon = React.lazy(() => import('./tools/converters/weight-converter/milligram/MilligramtoTon'));
const GramtoMicrogram = React.lazy(() => import('./tools/converters/weight-converter/gram/GramtoMicrogram'));
const GramtoMilligram = React.lazy(() => import('./tools/converters/weight-converter/gram/GramtoMilligram'));
const GramtoKilogram = React.lazy(() => import('./tools/converters/weight-converter/gram/GramtoKilogram'));
const GramtoMetricton = React.lazy(() => import('./tools/converters/weight-converter/gram/GramtoMetricton'));
const GramtoOunce = React.lazy(() => import('./tools/converters/weight-converter/gram/GramtoOunce'));
const GramtoPound = React.lazy(() => import('./tools/converters/weight-converter/gram/GramtoPound'));
const GramtoStone = React.lazy(() => import('./tools/converters/weight-converter/gram/GramtoStone'));
const GramtoTon = React.lazy(() => import('./tools/converters/weight-converter/gram/GramtoTon'));
const KilogramtoMicrogram = React.lazy(() => import('./tools/converters/weight-converter/kilogram/KilogramtoMicrogram'));
const KilogramtoMilligram =  React.lazy(() => import('./tools/converters/weight-converter/kilogram/KilogramtoMilligram'));
const KilogramtoGram = React.lazy(() => import('./tools/converters/weight-converter/kilogram/KilogramtoGram'));
const KilogramtoMetricton = React.lazy(() => import('./tools/converters/weight-converter/kilogram/KilogramtoMetricton'));
const KilogramtoOunce = React.lazy(() => import('./tools/converters/weight-converter/kilogram/KilogramtoOunce'));
const KilogramtoPound = React.lazy(() => import('./tools/converters/weight-converter/kilogram/KilogramtoPound'));
const KilogramtoStone = React.lazy(() => import('./tools/converters/weight-converter/kilogram/KilogramtoStone'));
const KilogramtoTon = React.lazy(() => import('./tools/converters/weight-converter/kilogram/KilogramtoTon'));
const MetrictontoMicrogram = React.lazy(() => import('./tools/converters/weight-converter/metricton/MetrictontoMicrogram'));
const MetrictontoMilligram = React.lazy(() => import('./tools/converters/weight-converter/metricton/MetrictontoMilligram'));
const MetrictontoGram = React.lazy(() => import('./tools/converters/weight-converter/metricton/MetrictontoGram'));
const MetrictontoKilogram = React.lazy(() => import('./tools/converters/weight-converter/metricton/MetrictontoKilogram'));
const MetrictontoOunce = React.lazy(() => import('./tools/converters/weight-converter/metricton/MetrictontoOunce'));
const MetrictontoPound = React.lazy(() => import('./tools/converters/weight-converter/metricton/MetrictontoPound'));
const MetrictontoStone = React.lazy(() => import('./tools/converters/weight-converter/metricton/MetrictontoStone'));
const MetrictontoTon = React.lazy(() => import('./tools/converters/weight-converter/metricton/MetrictontoTon'));
const OuncetoMicrogram = React.lazy(() => import('./tools/converters/weight-converter/ounce/OuncetoMicrogram'));
const OuncetoMilligram = React.lazy(() => import('./tools/converters/weight-converter/ounce/OuncetoMilligram'));
const OuncetoGram = React.lazy(() => import('./tools/converters/weight-converter/ounce/OuncetoGram'));
const OuncetoKilogram = React.lazy(() => import('./tools/converters/weight-converter/ounce/OuncetoKilogram'));
const OuncetoMetricton = React.lazy(() => import('./tools/converters/weight-converter/ounce/OuncetoMetricton'));
const OuncetoPound = React.lazy(() => import('./tools/converters/weight-converter/ounce/OuncetoPound'));
const OuncetoStone = React.lazy(() => import('./tools/converters/weight-converter/ounce/OuncetoStone'));
const OuncetoTon = React.lazy(() => import('./tools/converters/weight-converter/ounce/OuncetoTon'));
const PoundtoMicrogram = React.lazy(() => import('./tools/converters/weight-converter/pound/PoundtoMicrogram'));
const PoundtoMilligram = React.lazy(() => import('./tools/converters/weight-converter/pound/PoundtoMilligram'));
const PoundtoKilogram = React.lazy(() => import('./tools/converters/weight-converter/pound/PoundtoKilogram'));
const PoundtoMetricton = React.lazy(() => import('./tools/converters/weight-converter/pound/PoundtoMetricton'));
const PoundtoOunce = React.lazy(() => import('./tools/converters/weight-converter/pound/PoundtoOunce'));
const PoundtoStone = React.lazy(() => import('./tools/converters/weight-converter/pound/PoundtoStone'));
const PoundtoGram = React.lazy(() => import('./tools/converters/weight-converter/pound/PoundtoGram'));
const PoundtoTon = React.lazy(() => import('./tools/converters/weight-converter/pound/PoundtoTon'));
const StonetoMicrogram = React.lazy(() => import('./tools/converters/weight-converter/stone/StonetoMicrogram'));
const StonetoMilligram = React.lazy(() => import('./tools/converters/weight-converter/stone/StonetoMilligram'));
const StonetoGram = React.lazy(() => import('./tools/converters/weight-converter/stone/StonetoGram'));
const StonetoKilogram = React.lazy(() => import('./tools/converters/weight-converter/stone/StonetoKilogram'));
const StonetoMetricton = React.lazy(() => import('./tools/converters/weight-converter/stone/StonetoMetricton'));
const StonetoOunce = React.lazy(() => import('./tools/converters/weight-converter/stone/StonetoOunce'));
const StonetoPound = React.lazy(() => import('./tools/converters/weight-converter/stone/StonetoPound'));
const StonetoTon = React.lazy(() => import('./tools/converters/weight-converter/stone/StonetoTon'));
const TontoMicrogram = React.lazy(() => import('./tools/converters/weight-converter/ton/TontoMicrogram'));
const TontoMilligram = React.lazy(() => import('./tools/converters/weight-converter/ton/TontoMilligram'));
const TontoGram = React.lazy(() => import('./tools/converters/weight-converter/ton/TontoGram'));
const TontoKilogram = React.lazy(() => import('./tools/converters/weight-converter/ton/TontoKilogram'));
const TontoMetricton = React.lazy(() => import('./tools/converters/weight-converter/ton/TontoMetricton'));
const TontoOunce = React.lazy(() => import('./tools/converters/weight-converter/ton/TontoOunce'));
const TontoPound = React.lazy(() => import('./tools/converters/weight-converter/ton/TontoPound'));
const TontoStone = React.lazy(() => import('./tools/converters/weight-converter/ton/TontoStone'));

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Suspense >
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<FullwidthLayout><Home/></FullwidthLayout>}/>

        {redirectRoutes.map((route, index) => (
        <Route
          key={index}
          path={route.from}
          element={<Navigate to={route.to} replace />}
        />
      ))}

        {/* Text tools Section */}
        <Route path="/text-tools" element={<FullwidthLayout><TextTools/></FullwidthLayout>}/>

            {/* Text Counters */}
            <Route path="/text-tools/word-counter" element={<SidebarLayout><WordCounter/></SidebarLayout>}/>
            <Route path="/text-tools/character-counter" element={<SidebarLayout><CharacterCounter/></SidebarLayout>}/>
            <Route path="/text-tools/line-counter" element={<SidebarLayout><LineCounter/></SidebarLayout>}/>
            <Route path="/text-tools/letter-counter" element={<SidebarLayout><LetterCounter/></SidebarLayout>}/>

            {/* Text Converters */}
            <Route path="/text-tools/uppercase" element={<SidebarLayout><UppercaseConverter/></SidebarLayout>}/>
            <Route path="/text-tools/lowercase" element={<SidebarLayout><LowercaseConverter/></SidebarLayout>}/>
            <Route path="/text-tools/capitalise" element={<SidebarLayout><CapitaliseConverter/></SidebarLayout>}/>

        {/* Calculators Section */}
        <Route path="/calculators" element={<FullwidthLayout><Calculators/></FullwidthLayout>}/>

            {/* Time Calculators */}
            <Route path="/calculators/age-calculator" element={<SidebarLayout><AgeCalculator/></SidebarLayout>}/>
            <Route path="/calculators/birthday-calculator" element={<SidebarLayout><BirthdayCalculator/></SidebarLayout>}/>
            <Route path="/calculators/date-calculator" element={<SidebarLayout><DateCalculator/></SidebarLayout>}/>

            {/* Fitness Calculators */}
            <Route path="/calculators/bmi-calculator" element={<SidebarLayout><BmiCalculator/></SidebarLayout>}/>
            <Route path="/calculators/calories-calculator" element={<SidebarLayout><CaloriesCalculator/></SidebarLayout>}/>

            {/* Professional Calculators */}
            <Route path="/calculators/hourly-to-salary-calculator" element={<SidebarLayout><HourlytoSalaryCalculator/></SidebarLayout>}/>


            {/* Productivity Section */}
            <Route path="/productivity" element={<FullwidthLayout><Productivity/></FullwidthLayout>}/>
            <Route path="/*" element={<JobsRouter />} />
            
            
        
        {/* Converters Section */}
        <Route path="/converters" element={<FullwidthLayout><Converters/></FullwidthLayout>}/>
          
            {/* Number Converters */}
            <Route path="/converters/binary-to-decimal" element={<SidebarLayout><BinarytoDecimal /></SidebarLayout>} />
            <Route path="/converters/decimal-to-binary" element={<SidebarLayout><DecimaltoBinary /></SidebarLayout>} />
           
            {/* Temperature Converters */}
            <Route path="/converters/celsius-to-fahrenheit" element={<SidebarLayout><CelsiustoFahrenheit /></SidebarLayout>} />
            <Route path="/converters/celsius-to-kelvin" element={<SidebarLayout><CelsiustoKelvin /></SidebarLayout>} />
            <Route path="/converters/fahrenheit-to-celsius" element={<SidebarLayout><FahrenheittoCelsius /></SidebarLayout>} />
            <Route path="/converters/fahrenheit-to-kelvin" element={<SidebarLayout><FahrenheittoKelvin /></SidebarLayout>} />
            <Route path="/converters/kelvin-to-celsius" element={<SidebarLayout><KelvintoCelsius /></SidebarLayout>} />
            <Route path="/converters/kelvin-to-fahrenheit" element={<SidebarLayout><KelvintoFahrenheit /></SidebarLayout>} />

            {/* Weight Converters */}
            <Route path="/converters/microgram-to-milligram" element={<SidebarLayout><MicrogramtoMilligram /></SidebarLayout>} />
            <Route path="/converters/microgram-to-gram" element={<SidebarLayout><MicrogramtoGram /></SidebarLayout>} />
            <Route path="/converters/microgram-to-kilogram" element={<SidebarLayout><MicrogramtoKilogram /></SidebarLayout>} />
            <Route path="/converters/microgram-to-metricton" element={<SidebarLayout><MicrogramtoMetricton /></SidebarLayout>} />
            <Route path="/converters/microgram-to-ounce" element={<SidebarLayout><MicrogramtoOunce /></SidebarLayout>} />
            <Route path="/converters/microgram-to-pound" element={<SidebarLayout><MicrogramtoPound /></SidebarLayout>} />
            <Route path="/converters/microgram-to-stone" element={<SidebarLayout><MicrogramtoStone /></SidebarLayout>} />
            <Route path="/converters/microgram-to-ton" element={<SidebarLayout><MicrogramtoTon /></SidebarLayout>} />

            <Route path="/converters/milligram-to-microgram" element={<SidebarLayout><MilligramtoMicrogram /></SidebarLayout>} />
            <Route path="/converters/milligram-to-gram" element={<SidebarLayout><MilligramtoGram /></SidebarLayout>} />
            <Route path="/converters/milligram-to-kilogram" element={<SidebarLayout><MilligramtoKilogram /></SidebarLayout>} />
            <Route path="/converters/milligram-to-metricton" element={<SidebarLayout><MilligramtoMetricton /></SidebarLayout>} />
            <Route path="/converters/milligram-to-ounce" element={<SidebarLayout><MilligramtoOunce /></SidebarLayout>} />
            <Route path="/converters/milligram-to-pound" element={<SidebarLayout><MilligramtoPound /></SidebarLayout>} />
            <Route path="/converters/milligram-to-stone" element={<SidebarLayout><MilligramtoStone /></SidebarLayout>} />
            <Route path="/converters/milligram-to-ton" element={<SidebarLayout><MilligramtoTon /></SidebarLayout>} />

            <Route path="/converters/gram-to-microgram" element={<SidebarLayout><GramtoMicrogram /></SidebarLayout>} />
            <Route path="/converters/gram-to-milligram" element={<SidebarLayout><GramtoMilligram /></SidebarLayout>} />
            <Route path="/converters/gram-to-kilogram" element={<SidebarLayout><GramtoKilogram /></SidebarLayout>} />
            <Route path="/converters/gram-to-metricton" element={<SidebarLayout><GramtoMetricton /></SidebarLayout>} />
            <Route path="/converters/gram-to-ounce" element={<SidebarLayout><GramtoOunce /></SidebarLayout>} />
            <Route path="/converters/gram-to-pound" element={<SidebarLayout><GramtoPound /></SidebarLayout>} />
            <Route path="/converters/gram-to-stone" element={<SidebarLayout><GramtoStone /></SidebarLayout>} />
            <Route path="/converters/gram-to-ton" element={<SidebarLayout><GramtoTon /></SidebarLayout>} />

            <Route path="/converters/kilogram-to-microgram" element={<SidebarLayout><KilogramtoMicrogram /></SidebarLayout>} />
            <Route path="/converters/kilogram-to-milligram" element={<SidebarLayout><KilogramtoMilligram /></SidebarLayout>} />
            <Route path="/converters/kilogram-to-gram" element={<SidebarLayout><KilogramtoGram /></SidebarLayout>} />
            <Route path="/converters/kilogram-to-metricton" element={<SidebarLayout><KilogramtoMetricton /></SidebarLayout>} />
            <Route path="/converters/kilogram-to-ounce" element={<SidebarLayout><KilogramtoOunce /></SidebarLayout>} />
            <Route path="/converters/kilogram-to-pound" element={<SidebarLayout><KilogramtoPound /></SidebarLayout>} />
            <Route path="/converters/kilogram-to-stone" element={<SidebarLayout><KilogramtoStone /></SidebarLayout>} />
            <Route path="/converters/kilogram-to-ton" element={<SidebarLayout><KilogramtoTon /></SidebarLayout>} />

            <Route path="/converters/metricton-to-microgram" element={<SidebarLayout><MetrictontoMicrogram /></SidebarLayout>} />
            <Route path="/converters/metricton-to-milligram" element={<SidebarLayout><MetrictontoMilligram /></SidebarLayout>} />
            <Route path="/converters/metricton-to-gram" element={<SidebarLayout><MetrictontoGram /></SidebarLayout>} />
            <Route path="/converters/metricton-to-kilogram" element={<SidebarLayout><MetrictontoKilogram /></SidebarLayout>} />
            <Route path="/converters/metricton-to-ounce" element={<SidebarLayout><MetrictontoOunce /></SidebarLayout>} />
            <Route path="/converters/metricton-to-pound" element={<SidebarLayout><MetrictontoPound /></SidebarLayout>} />
            <Route path="/converters/metricton-to-stone" element={<SidebarLayout><MetrictontoStone /></SidebarLayout>} />
            <Route path="/converters/metricton-to-ton" element={<SidebarLayout><MetrictontoTon /></SidebarLayout>} />

            <Route path="/converters/ounce-to-microgram" element={<SidebarLayout><OuncetoMicrogram /></SidebarLayout>} />
            <Route path="/converters/ounce-to-milligram" element={<SidebarLayout><OuncetoMilligram /></SidebarLayout>} />
            <Route path="/converters/ounce-to-gram" element={<SidebarLayout><OuncetoGram /></SidebarLayout>} />
            <Route path="/converters/ounce-to-kilogram" element={<SidebarLayout><OuncetoKilogram /></SidebarLayout>} />
            <Route path="/converters/ounce-to-metricton" element={<SidebarLayout><OuncetoMetricton /></SidebarLayout>} />
            <Route path="/converters/ounce-to-pound" element={<SidebarLayout><OuncetoPound /></SidebarLayout>} />
            <Route path="/converters/ounce-to-stone" element={<SidebarLayout><OuncetoStone /></SidebarLayout>} />
            <Route path="/converters/ounce-to-ton" element={<SidebarLayout><OuncetoTon /></SidebarLayout>} />

            <Route path="/converters/pound-to-microgram" element={<SidebarLayout><PoundtoMicrogram /></SidebarLayout>} />
            <Route path="/converters/pound-to-milligram" element={<SidebarLayout><PoundtoMilligram /></SidebarLayout>} />
            <Route path="/converters/pound-to-gram" element={<SidebarLayout><PoundtoGram /></SidebarLayout>} />
            <Route path="/converters/pound-to-kilogram" element={<SidebarLayout><PoundtoKilogram /></SidebarLayout>} />
            <Route path="/converters/pound-to-metricton" element={<SidebarLayout><PoundtoMetricton /></SidebarLayout>} />
            <Route path="/converters/pound-to-ounce" element={<SidebarLayout><PoundtoOunce /></SidebarLayout>} />
            <Route path="/converters/pound-to-stone" element={<SidebarLayout><PoundtoStone /></SidebarLayout>} />
            <Route path="/converters/pound-to-ton" element={<SidebarLayout><PoundtoTon /></SidebarLayout>} />

            <Route path="/converters/stone-to-microgram" element={<SidebarLayout><StonetoMicrogram /></SidebarLayout>} />
            <Route path="/converters/stone-to-milligram" element={<SidebarLayout><StonetoMilligram /></SidebarLayout>} />
            <Route path="/converters/stone-to-gram" element={<SidebarLayout><StonetoGram /></SidebarLayout>} />
            <Route path="/converters/stone-to-kilogram" element={<SidebarLayout><StonetoKilogram /></SidebarLayout>} />
            <Route path="/converters/stone-to-metricton" element={<SidebarLayout><StonetoMetricton /></SidebarLayout>} />
            <Route path="/converters/stone-to-ounce" element={<SidebarLayout><StonetoOunce /></SidebarLayout>} />
            <Route path="/converters/stone-to-pound" element={<SidebarLayout><StonetoPound /></SidebarLayout>} />
            <Route path="/converters/stone-to-ton" element={<SidebarLayout><StonetoTon /></SidebarLayout>} />

            <Route path="/converters/ton-to-microgram" element={<SidebarLayout><TontoMicrogram /></SidebarLayout>} />
            <Route path="/converters/ton-to-milligram" element={<SidebarLayout><TontoMilligram /></SidebarLayout>} />
            <Route path="/converters/ton-to-gram" element={<SidebarLayout><TontoGram /></SidebarLayout>} />
            <Route path="/converters/ton-to-kilogram" element={<SidebarLayout><TontoKilogram /></SidebarLayout>} />
            <Route path="/converters/ton-to-metricton" element={<SidebarLayout><TontoMetricton /></SidebarLayout>} />
            <Route path="/converters/ton-to-ounce" element={<SidebarLayout><TontoOunce /></SidebarLayout>} />
            <Route path="/converters/ton-to-pound" element={<SidebarLayout><TontoPound /></SidebarLayout>} />
            <Route path="/converters/ton-to-stone" element={<SidebarLayout><TontoStone /></SidebarLayout>} />

      </Routes>
      </Suspense>
    </BrowserRouter>
    </div>
  );
}

export default App;
