import React, { useEffect, useState } from 'react'
import classes from "./style/JobFinder.module.css";

const JobFinder = ({jobTitle}) => {
    const [addInput, setAddInput] = useState("global")
        const [outputValue, setOutputValue] = useState();
        // const [errorMessage, setErrorMessage] = useState();

        function generateRandomNumber(min = 1000, max = 5000) {
            const random =  Math.floor(Math.random() * (max - min + 1)) + min;
            setOutputValue(random);
          }
    
          const handleRedirect = () => {
            if(addInput === "us"){
                window.open( `https://www.wiraa.com/?type=workfromhome-jobs&title=${jobTitle}`, "_blank");
            }else if(addInput === "india"){
                window.open( `https://www.wiraa.com/?type=remote-jobs&title=${jobTitle}`, "_blank");
            }else{
                window.open( `https://www.wiraa.com/?type=global-jobs&title=${jobTitle}`, "_blank");
            }
          };

          useEffect(() => {
            generateRandomNumber();
          },[])
        

  return (
      <div className={classes.innerCon}>
        <div className={classes.additionalInputCon}>
            <div className={classes.additionalInputDiv}>
                <p className={classes.inputplaceholder}>Select Country</p>
                <div className={classes.radioInputCon}>
                <div>
                <input className={classes.radioInput} type="radio" id="global" name="country" value="global" checked={addInput === "global"} onChange={(e) => setAddInput(e.target.value)}/>
                <label htmlFor="global" className={classes.radioInputlabel}>Global</label>
                </div><div>
                <input className={classes.radioInput2} type="radio" id="us" name="country" value="us" checked={addInput === "us"} onChange={(e) => setAddInput(e.target.value)}/>
                    <label htmlFor="us" className={classes.radioInputlabel}>United States</label>
                </div><div>
                <input className={classes.radioInput2} type="radio" id="india" name="country" value="india" checked={addInput === "india"} onChange={(e) => setAddInput(e.target.value)}/>
                    <label htmlFor="india" className={classes.radioInputlabel}>India</label>
                </div>
                </div>
            </div>

            {/* {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>} */}
        </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleRedirect}>View {outputValue}+ {jobTitle} Jobs</button>
            {/* <button className={classes.clearBtn}>Clear</button> */}
        </div>
      </div>
  )
}

export default JobFinder
