import React from 'react'
import classes from "./styles/RightSideWiraaAds.module.css";


export const RightSideWiraaAds = () => {
  return (
    <a href="https://www.wiraa.com/" target="_blank" rel="noopener noreferrer" className={classes.link}>
    <div className={classes.container}>
        <h6 className={classes.title}>Need extra income? <br/>Start remote work!</h6>
        <p className={classes.para}>Browse 1,000+ Remote & Freelance Jobs from trusted companies.</p>
        <div className={classes.btnCon}><button className={classes.btn}>Explore Now</button></div>
    </div></a>
  )
}
