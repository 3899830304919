import React from 'react'
import classes from "./styles/Home.module.css";
import { NavLink } from 'react-router-dom';
import { Helmet } from "react-helmet";
import arrowIcon from "../assets/arrow-upper-right.png";
const Home = () => {
  return (
    <div className={classes.Container}>
        <Helmet>
                <title>Wiraa Tools: Your All-in-One Calculation Hub</title>
                <meta name="description" content="Discover Wiraa Tools, a collection of smart calculators and utilities designed to simplify tasks, boost efficiency, and support professionals in their daily workflow." />
        </Helmet>
        <h1 className={classes.title}>Wiraa Tools: Efficient, Easy, and Reliable</h1>
        <p className={classes.subTitle}>Powerful Tools to Streamline Your Work and Enhance Productivity</p>


        <div className={classes.conColor1}>
        <div className={classes.subHeadingCon}>
        <h3 className={classes.subHeadings}>Text</h3>
        <a className={classes.pageLink} href="/text-tools"><img src={arrowIcon} alt='link-icon'/></a>
        </div>
        
        <div className={`${classes.toolsContainer}`}>
                    <NavLink to="/text-tools/word-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Word Counter</NavLink>
                    <NavLink to="/text-tools/character-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Character Counter</NavLink>
                    <NavLink to="/text-tools/line-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Line Counter</NavLink>
                    <NavLink to="/text-tools/letter-counter" className={({ isActive }) => (isActive ? classes.active : "")}>Letter Counter</NavLink>
                    <NavLink to="/text-tools/uppercase" className={({ isActive }) => (isActive ? classes.active : "")}>Uppercase Converter</NavLink>
                    <NavLink to="/text-tools/lowercase" className={({ isActive }) => (isActive ? classes.active : "")}>Lowercase Converter</NavLink>
                    <NavLink to="/text-tools/capitalise" className={({ isActive }) => (isActive ? classes.active : "")}>Capitalise Converter</NavLink>     
        </div> </div>


        <div className={classes.conColor2}>   
        <div className={classes.subHeadingCon}>
        <h3 className={classes.subHeadings}>Calculators</h3>
        <a className={classes.pageLink} href="/calculators"><img src={arrowIcon} alt='link-icon'/></a>
        </div>
        <div className={`${classes.toolsContainer}`}>        
                    <NavLink to="/calculators/age-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Age Calculator</NavLink>
                    <NavLink to="/calculators/birthday-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Birthday Calculator</NavLink>
                    <NavLink to="/calculators/date-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Date Calculator</NavLink>
                    <NavLink to="/calculators/bmi-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>BMI Calculator</NavLink>
                    <NavLink to="/calculators/calories-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Calories Calculator</NavLink>
                    <NavLink to="/calculators/hourly-to-salary-calculator" className={({ isActive }) => (isActive ? classes.active : "")}>Hourly To Salary Calculator</NavLink>
          </div> </div>

          <div className={classes.conColor4}>
          <div className={classes.subHeadingCon}>
        <h3 className={classes.subHeadings}>Productivity</h3>
        <a className={classes.pageLink} href="/productivity"><img src={arrowIcon} alt='link-icon'/></a>
        </div>
          <div className={`${classes.toolsContainer}`}>
                <NavLink to="/productivity/developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Developer Job Finder</NavLink>
                <NavLink to="/productivity/web-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Web Developer Job Finder</NavLink>
                <NavLink to="/productivity/app-developer-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>App Developer Job Finder</NavLink>
                <NavLink to="/productivity/react-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>React Js Job Finder</NavLink>
                <NavLink to="/productivity/react-native-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>React Native Job Finder</NavLink>
                <NavLink to="/productivity/java-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Java Job Finder</NavLink>
                <NavLink to="/productivity/angular-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Angular Job Finder</NavLink>
                <NavLink to="/productivity/css-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>CSS Job Finder</NavLink>
                <NavLink to="/productivity/html-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Html Job Finder</NavLink>
                <NavLink to="/productivity/next-js-jobs" className={({ isActive }) => (isActive ? classes.active : "")}>Next Js Job Finder</NavLink>
        </div>
        </div>

          <div className={classes.conColor3}>
          <div className={classes.subHeadingCon}>
        <h3 className={classes.subHeadings}>Converters</h3>
        <a className={classes.pageLink} href="/converters"><img src={arrowIcon} alt='link-icon'/></a>
        </div>
          <div className={`${classes.toolsContainer}`}>
                    <NavLink to="/converters/binary-to-decimal" className={({ isActive }) => (isActive ? classes.active : "")}>Binary to decimal</NavLink>
                    <NavLink to="/converters/decimal-to-binary" className={({ isActive }) => (isActive ? classes.active : "")}> Decimal to binary</NavLink>

                    <NavLink to="/converters/celsius-to-fahrenheit" className={({ isActive }) => (isActive ? classes.active : "")}>Celsius to fahrenheit</NavLink>
                    <NavLink to="/converters/celsius-to-kelvin" className={({ isActive }) => (isActive ? classes.active : "")}>Celsius to kelvin</NavLink>
                    <NavLink to="/converters/fahrenheit-to-celsius" className={({ isActive }) => (isActive ? classes.active : "")}>Fahrenheit to celsius</NavLink>
                    <NavLink to="/converters/fahrenheit-to-kelvin" className={({ isActive }) => (isActive ? classes.active : "")}>Fahrenheit to kelvin</NavLink>
                    <NavLink to="/converters/kelvin-to-celsius" className={({ isActive }) => (isActive ? classes.active : "")}>Kelvin to celsius</NavLink>
                    <NavLink to="/converters/kelvin-to-fahrenheit" className={({ isActive }) => (isActive ? classes.active : "")}>Kelvin to fahrenheit</NavLink>


                    <NavLink to="/converters/microgram-to-milligram" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to milligram</NavLink>
                    <NavLink to="/converters/microgram-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to gram</NavLink>
                    <NavLink to="/converters/microgram-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to kilogram</NavLink>
                    <NavLink to="/converters/microgram-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to metric ton</NavLink>
                    <NavLink to="/converters/microgram-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to Ounce</NavLink>
                    <NavLink to="/converters/microgram-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to Pound</NavLink>
                    <NavLink to="/converters/microgram-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to Stone</NavLink>
                    <NavLink to="/converters/microgram-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Microgram to Ton</NavLink>

                    <NavLink to="/converters/milligram-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to microgram</NavLink>
                    <NavLink to="/converters/milligram-to-gram" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to gram</NavLink>
                    <NavLink to="/converters/milligram-to-kilogram" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to kilogram</NavLink>
                    <NavLink to="/converters/milligram-to-metricton" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to metric ton</NavLink>
                    <NavLink to="/converters/milligram-to-ounce" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to ounce</NavLink>
                    <NavLink to="/converters/milligram-to-pound" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to pound</NavLink>
                    <NavLink to="/converters/milligram-to-stone" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to stone</NavLink>
                    <NavLink to="/converters/milligram-to-ton" className={({ isActive }) => (isActive ? classes.active : "")}>Milligram to ton</NavLink>
                    
                    <NavLink to="/converters/gram-to-microgram" className={({ isActive }) => (isActive ? classes.active : "")}>Gram to microgram</NavLink>
        </div>
        </div>
    </div>
  )
}

export default Home;
