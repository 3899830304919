import React from 'react'
import classes from "./SidebarLayout.module.css";
import Navbar from '../common/Navbar';
import Footer from '../common/Footer';

const SidebarLayout = ({ children }) => {
    return (
      <>
        <div className={classes.navbar}>
        <Navbar />
        </div>
        <div className={classes.Container}>
          <div className={classes.pages}>
            {children}
          </div>
        </div>
        <Footer />
      </>
    );
  };

export default SidebarLayout;
