import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const UiDesignerJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>UI Designer Jobs</title>
                <meta name="description" content="Find the best UI designer jobs in the US and India. Connect with leading companies and advance your career with UI Designer Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>UI Designer Job Finder</h1>
        <p className={classes.subTitle}>Explore Top UI Designer Jobs to Build Engaging and User-Friendly User Interfaces</p>
      <JobFinder jobTitle={"UI Designer"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default UiDesignerJob;
