import React from 'react'
import classes from "../style/JobFinderPage.module.css";
import JobFinder from '../JobFinder';
import RightSidebar from '../../../../common/RightSidebar';
import { FullWidthWiraaAds } from '../../../../common/Ads/FullWidthWiraaAds';
import { Helmet } from 'react-helmet';
import JobSearch from '../JobSearch';

const AwsJob = () => {
  return (
    <div className={classes.page}>
      <Helmet>
                <title>AWS Jobs</title>
                <meta name="description" content="Find the best AWS jobs in the US and India. Connect with leading companies and advance your career with AWS Job Finder." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>AWS Job Finder</h1>
        <p className={classes.subTitle}>Explore Top AWS Job Opportunities to Build and Manage Cloud Solutions</p>
      <JobFinder jobTitle={"AWS"}/>
      <JobSearch/>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default AwsJob;
