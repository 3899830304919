import React, { useState } from 'react'
import classes from "./styles/HourlytoSalary.module.css";
import RightSidebar from '../../../common/RightSidebar';
import { Helmet } from 'react-helmet';
import { FullWidthWiraaAds } from '../../../common/Ads/FullWidthWiraaAds';

const HourlytoSalaryCalculator = () => {
    const [inputValue, setInputValue] = useState();
    const [addInput, setAddInput] = useState({"hour" : "8", "day" : "6"})
    const [outputValue, setOutputValue] = useState();
    const [errorMessage, setErrorMessage] = useState();


    const handleSalaryCalculation = () => {
        // Check if the input date is valid
        if (!inputValue || inputValue < 0) {
            setErrorMessage("Please enter a valid hourly wage.");
            setOutputValue("");
            return;
        }
    
        const { hour, day} = addInput;
    
        // Check if the additional inputs are valid numbers
        if (
            isNaN(hour) || hour < 0 ||
            isNaN(day) || day < 0 || day > 7
        ) {
            setErrorMessage("Please enter valid numbers for hours per day and days per week.");
            setOutputValue("");
            return;
        }
    
        setErrorMessage(""); // Clear any existing errors
        const weeklySalary = inputValue * hour * day; // Weekly salary
        const monthlySalary = (weeklySalary * 52) / 12;
        setOutputValue(monthlySalary);
    };
    
      const handleClearBtn = () => {
        setInputValue("");
        setOutputValue("");
        setErrorMessage("");
      }  

  return (
    <div className={classes.page}>
      <Helmet>
                <title>Hourly To Salary Calculator - Wiraa tools</title>
                <meta name="description" content="Use our Hourly to Salary Calculator to easily convert your hourly pay into a yearly, monthly, weekly, or daily salary. Perfect for budgeting and financial planning." />
        </Helmet>
    <div className={classes.container}>
        <h1 className={classes.title}>Hourly To Salary Calculator</h1>
        <p className={classes.subTitle}>Convert your hourly wage into an monthly salary effortlessly with our Hourly to Salary Calculator.</p>
      <div className={classes.innerCon}>
        <div className={classes.inputCon}>
            <p className={classes.inputplaceholder}>Hourly salary</p>
            <input value={inputValue} onChange={(e) => setInputValue(e.target.value)} className={classes.inputfield} placeholder='Enter your hourly salary'/>
            {errorMessage && <p className={classes.errorMsg}>{errorMessage}</p>}
        </div>
        <div className={classes.additionalInputCon}>
            <div className={classes.additionalInputDiv}>
                <p className={classes.addplaceholder}>Hours a day</p>
                <input value={addInput.hour} onChange={(e) => setAddInput({ ...addInput, hour: e.target.value })} className={classes.addInputfield} placeholder='Working hours in a day...'/>
            </div>
            <div className={classes.additionalInputDiv}>
                <p className={classes.addplaceholder}>Days in week</p>
                <input value={addInput.day} onChange={(e) => setAddInput({ ...addInput, day: e.target.value })} className={classes.addInputfield} placeholder='Working days in a week...'/>
            </div>
        </div>
        <div className={classes.btnCon}>
            <button className={classes.convertBtn} onClick={handleSalaryCalculation}>Calculate</button>
            <button className={classes.clearBtn} onClick={handleClearBtn}>Clear</button>
        </div>
        <div className={classes.outputCon}>
            <p className={classes.outputplaceholder}>Monthly Salary</p>
            <p className={classes.outputfield}>{outputValue}</p>
        </div>
      </div>

      <div className={classes.ads}>
        <FullWidthWiraaAds/>
     </div>

      <div className={classes.contentContainer}>
      </div>
    </div>
    <div className={classes.rightsidebar}>
      <RightSidebar/>
    </div>
    </div>
  )
}

export default HourlytoSalaryCalculator;
